.project
{
    margin: 50px 0px;
    margin-left:80px ;
}

.project hr
{
    color: #138781;
    width: 400px;
    margin: 10px auto;
}

.project h2
{
    font-weight: bold;
    color: #138781;
}

#ads
{
    margin: 30px 0px 30px 0px;
}

#ads .card-notify-badge
{
    position: absolute;
    left: -10px;
    top: -20px;
    background: #f29f67;
    text-align: center;
    border-radius: 30px;
    color: black;
    padding: 5px 10px;
    font-size: 14px;
}

.project
.card img
{
    height: 200px;
    width: 100%;
}

.project
.card-detail-badge
{
    background-color: #e0b50f;
    text-align: center;
    border-radius: 30px;
    color: black;
    padding: 5px 10px;
    font-size: 14px;
}

.project
.card-image-overly
{
    font-size: 20px;
}

.project
.card-image-overly span
{
    display: inline-block;
    margin-left: 5px;
}

.project
.card:hover
{
    background-color: white;
    border-radius: 4px;
    box-shadow: 12px 15px 20px 0px rgb(110, 92, 92);
    transition: all 1s ease;
}

.project
.ad-btn
{
    text-transform: uppercase;
    width: 150px;
    height: 40px;
    display: block;
    text-align: center;
    background-color: #138781;
    color: white;
    border-radius: 80px;
    font-size: 16px;
    line-height: 35px;
    text-decoration: none;
    margin: 20px auto 20px auto;
    overflow: hidden;
    position: relative;
}



.project
.ad-btn1
{
    text-transform: uppercase;
    width: 150px;
    height: 40px;
    display: block;
    text-align: center;
    background-color: rgb(10, 95, 206);
    color: white;
    border-radius: 80px;
    font-size: 16px;
    line-height: 35px;
    text-decoration: none;
    margin: 20px auto 20px auto;
    overflow: hidden;
    position: relative;
}

.project
.ad-btn:hover
{
    background-color: white;
    color: #138781;
    background: transparent;
    border: 2px solid #138781;
    transition: all 0.5s ease;
    box-shadow: 12px 15px 20px 0px rgb(110, 92, 92);
}


.project
.ad-btn1:hover
{
    background-color: white;
    color: #138781;
    background: transparent;
    border: 2px solid #138781;
    transition: all 0.5s ease;
    box-shadow: 12px 15px 20px 0px rgb(110, 92, 92);
}






/* ====================================== */
@media (max-width:600px) 
{
    .project
    {
        padding: 0;
        margin: 0;
        margin-top: 60px;
    }

    .card
    {
        margin-left: 10px;
        margin-top: 25px;
        width: 100%;
    }

  
    
}