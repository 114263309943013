.education
{
    padding: 100px 0px;
    margin: 50px 0px;
    margin-left:130px ;
    background-color: rgb(225, 223, 223);
}

.education hr
{
    color: #138781;
    width: 400px;
    margin: 10px auto;
}

.education h2
{
    font-weight: bold;
    color: #138781;
}

.education
.vertical-timeline-element-title
{
    color: #138781;
}

.education
.vertical-timeline-element-subtitle
{
    color: #1e1e2c;
}




/* =================== */
@media (max-width:600px) 
{
    .education
    {
        margin: 0;
        padding: 0;
        margin-top: 30px;
    }

    .education h2
    {
        padding-top: 20px;
    }

    .education hr
    {
        width: 280px;
        margin-bottom: 10px;
    }
    
}