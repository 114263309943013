.home-container
{
    height: 320px;
    color: white;
    background-color: black;
    padding-left: 150px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.home-content 
h2
{
    padding: 70px;
    padding-top:80px;
    /* margin-left:150px; */
}

.home-content h1
{
    color: #e0b50f;
    /* margin-left: 150px; */
}

.home-buttons
{
    margin-left: 10px;
}

.home-buttons 
.btn-hire
{
    background-color: #3b8ff3;
    color: white;
    width: 160px;
}

.home-buttons 
.btn-cv
{
    background-color: #f29f67;
    color: white;
    margin-left: 20px;
}

.home-buttons 
.btn-cv:hover
{
    opacity: 0.8;
    transition: 1s;
}

.theme-btn
{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    background-color: #1e1e1e;
    margin-top: 20px;
    right: 0;
    text-align: center;
    position: fixed;
    cursor: pointer;
    border-top-left-radius: 10px ;
    border-bottom-left-radius: 10px;
    z-index: 1;
}


@media (max-width:600px) {
    .home-container
    {
        padding: 0;
        margin: 0;
        padding-top: 10px;
        margin-top: 50px;
    }
    
    .home-container h2
    {
        font-size: 24px;
    }
    .home-container h1
    {
        font-size: 24px;

    }
}





