.about
{
    margin: 30px 0px;
    padding: 20px;
    margin-left: 170px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.about-img img
{
    border-radius: 10px;
    width: 100%;
    height: 400px;
}

.about-content
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-content h1
{
    color: grey;
    font-family: cursive;
    border-bottom: 2px solid grey;
    text-align: center;
}

.about-content p
{
    justify-content: center;
    padding: 20px;
}






/* ============= Dark Mode ========== */

#dark .about
{
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.15);
}

#dark .about-content h1
{
    color: #b0a4a4;
    border-bottom: 2px solid #b0a4a4;

}


/* ====================== */

@media (max-width:600px)
{
    .about
    {
        margin: 0;
        padding: 0;
        margin-top: 20px;
        width: 100%;
    }

    .about-content h1
    {
        margin-top: 10px;
    }
    
}