.teachskill .card
{
    cursor: pointer;
}

.teachskill
{
    margin-left: 150px;
}

.teachskill .tech-icon
{
    font-size: 2rem;
    margin-right: 20px;
}

.teachskill .card:hover
{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-color: #138781;
    color: white;
    transition: 1s;
}


/* ==================================== */

@media (max-width:600px) 
{
    .teachskill
    {
        margin: 0;
        padding: 0;
        margin-top: 50px;  
        padding-right: 60px;   
    }

    .teachskill hr
    {
        width:280px ;
    }

   

   
} 







