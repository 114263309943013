.sidebar-section
{
    display: flex;
    background-color: black;
}

.sidebar
{
    height: 100vh;
    background-color: #1e1e2c;
    width: 80px;
    position: fixed;
}

.sidebar-toggle
{
    width: 210px;
}

.sidebar-toggle-icons
{
    display: flex;
    align-items: right;
    justify-content: right;
    cursor: pointer;
}

.sidebar-toggle-icons p svg
{
    background-color: #f29f67;
    color: white;
    margin-top: 30px;
}




@media (max-width:600px){
    .sidebar
    {
        display: none;
    }
    
}