.navbar-profile-pic
{
    height: 140px;
    width: 140px;
    display: flex;
    vertical-align: middle;
    margin: 0 auto;
    margin-top: 20px;
    margin-right: 20%;
}

.nav-item
{
    color: white;
    margin-top: 30px;
    /* text-align: center; */
    margin-left: 25px;
}

.nav-link
{
    margin-top: 20px;
    cursor: pointer;
}

.nav-link svg
{
    margin-right:10px ;
}

.nav-link:hover
{
    color:#f29f67 ;
}

.nav-link .active
{
    color:#f29f67 ;
    border-left: 5px solid #f29f67;
    padding-left: 10px;
    text-decoration: none;
}